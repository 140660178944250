<template>
  <el-row class="row" id="optometricEvaluation">
    <el-col class="col">
      <Title2
        style="margin-top: 10px;"
        :btnText="isFirstEvaluation ? 'Registrar' : 'Actualizar'"
        :title="'EVALUACIÓN OPTOMÉTRICA'"
        :label="label"
        v-on:before-submit="beforeSubmit"
        :patientId="patientId"
        :enableOftaDialog="true"
        :createdAt="createdAt"
        :appointment="appointmentId"
      />
      <div class="form">
        <el-container>
          <el-main>
            <el-form
              :model="form"
              ref="refId"
              :rules="rules"
              @submit.prevent
            >
              <div>
                <div class="row-no-center">
                  <el-form-item prop="info.weight" class="small" label="Peso">
                    <el-input v-model="form.info.weight" />
                  </el-form-item>
                  <el-form-item prop="info.size" class="small" label="Talla">
                    <el-input v-model="form.info.size" />
                  </el-form-item>
                </div>
                <div class="row-no-center">
                  <el-form-item prop="info.heartRate" class="small" label="Frecuencia Cardíaca">
                    <el-input v-model="form.info.heartRate" />
                  </el-form-item>
                  <el-form-item
                    prop="info.respiratoryRate"
                    class="small"
                    label="Frecuencia Respiratoria"
                  >
                    <el-input v-model="form.info.respiratoryRate" />
                  </el-form-item>
                  <el-form-item prop="info.bloodPressure" class="small sh" label="Tensión Arterial">
                    <el-input v-model="form.info.bloodPressure" />
                  </el-form-item>
                  <el-form-item prop="info.temperature" class="small sh" label="Temperatura">
                    <el-input v-model="form.info.temperature" />
                  </el-form-item>
                  <el-form-item
                    prop="hasSurgeries"
                    class="small"
                    label="¿Ha sido operado de los ojos?"
                  >
                    <el-select v-model="form.info.hasSurgeries">
                      <el-option
                        v-for="item in hasSurgeriesOpt"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    v-if="form.info.hasSurgeries"
                    prop="previousSurgery"
                    class="large added sh"
                    label="Cirugías Oculares Previas"
                  >
                    <el-input v-model="form.info.previousSurgery" />
                  </el-form-item>
                </div>
                <div class="row-no-center">
                  <el-form-item prop="info.medicines" class="large" label="Medicamentos">
                    <el-input
                      v-model="form.info.medicines"
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="info.currentCondition"
                    class="large"
                    label="Padecimientos Actuales"
                  >
                    <el-input
                      v-model="form.info.currentCondition"
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                    />
                  </el-form-item>
                  <el-form-item prop="info.comments" class="large" label="Comentarios Extra">
                    <el-input
                      v-model="form.info.comments"
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                    />
                  </el-form-item>
                </div>
              </div>

              <div style="width:fit-content;">
                <div class="row-no-center">
                  <p
                    style="width:20px;
                    color:#000000;
                    font-weight:bold;
                    font-size:13px;
                    margin:0px;
                    margin-top:20px
                    "
                    >
                    OD
                  </p>
                  <div style="padding-left:20px;">
                    <p style="color:#006EFF;font-weight:bold;font-size:15px!important;margin:0px;">
                      AGUDEZA VISUAL
                    </p>
                    <br>
                    <div class="row-no-center">
                      <el-form-item
                        prop="right.vaNoCorrection"
                        class="small"
                        style="padding-top:20px;"
                      >
                        <el-input v-model="form.right.vaNoCorrection" />
                      </el-form-item>
                    </div>
                  </div>
                  <div style="padding-left:20px;">
                    <p
                      style="
                        color: #006EFF;
                        font-weight:bold;
                        font-size:15px!important;
                        margin:0px;
                        text-align:center
                      "
                    >
                      PH
                    </p>
                    <br>
                    <div class="row-no-center">
                      <el-form-item
                        prop="right.refractionPh"
                        class="small"
                        style="margin-top:20px;"
                      >
                        <el-input v-model="form.right.refractionPh"/>
                      </el-form-item>
                    </div>
                  </div>
                  <div style="margin-left:19px;">
                    <p
                      style="
                        color: #006EFF;
                        font-weight:bold;
                        font-size:15px!important;
                        margin:0px;
                        text-align:center
                      "
                    >
                      REFRACCIÓN
                    </p>
                    <br>
                    <div class="row-no-center">
                      <el-form-item prop="right.refractionSphere" class="small" label="Esfera">
                        <el-input v-model="form.right.refractionSphere" />
                      </el-form-item>
                      <el-form-item prop="right.refractionCilinder" class="small" label="Cilindro">
                        <el-input v-model="form.right.refractionCilinder" />
                      </el-form-item>
                      <el-form-item prop="right.refractionAxis" class="small" label="Eje">
                      <el-input v-model="form.right.refractionAxis" />
                      </el-form-item>
                      <el-form-item prop="right.refractionAddition" class="small" label="Adición">
                        <el-input v-model="form.right.refractionAddition" />
                      </el-form-item>
                    </div>
                  </div>
                  <div style="margin-left:20px">
                    <p style="color:#006EFF;font-weight:bold;font-size:15px!important;margin:0px;">
                      CAPACIDAD VISUAL
                    </p>
                    <br>
                    <el-form-item
                      prop="right.vaWithCorrection"
                      class="small"
                      label="AV Con corrección"
                    >
                      <el-select
                        filterable
                        v-model="form.right.vaWithCorrection"
                        placeholder="Parámetros de AV"
                        style="width: 170px;"
                      >
                        <el-option
                          v-for="item in avParams"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                  <div style="margin-left: 41px">
                    <p style="color:#006EFF;font-weight:bold;font-size:15px!important;margin:0px;">
                      PRESIÓN OCULAR
                    </p>
                    <br>
                    <el-form-item
                      prop="right.intraocularPressure"
                      class="small"
                      label="Presión"
                    >
                      <el-input v-model="form.right.intraocularPressure" />
                    </el-form-item>
                  </div>
                  <div style="margin-left:15px;height:118px;">
                    <p
                      style="color:#006EFF;
                      font-weight:bold;
                      font-size:15px!important;
                      margin:0px;"
                      >
                      | SEMÁFORO DE LA VISIÓN
                    </p>
                    <br>
                    <div
                      style="width:200px; height: 45px; margin-top:16px;"
                      :class="
                        [
                          form.right.vaWithCorrection == 'N/A' ? 'semaforo-color-transparent':
                          form.right.vaWithCorrection == 'No Percibe Luz' ? 'semaforo-color-negro':
                          form.right.vaWithCorrection == 'Percepción de Luz'?'semaforo-color-negro':
                          form.right.vaWithCorrection == 'Prótesis'?'semaforo-color-negro':

                          form.right.vaWithCorrection == 'Movimiento Manos'? 'semaforo-color-rojo':
                          form.right.vaWithCorrection == 'Cuenta Dedos' ? 'semaforo-color-rojo' :
                          form.right.vaWithCorrection == '20/500' ? 'semaforo-color-rojo' :
                          form.right.vaWithCorrection == '20/400' ? 'semaforo-color-rojo' :
                          form.right.vaWithCorrection == '20/320' ? 'semaforo-color-rojo' :
                          form.right.vaWithCorrection == '20/250' ? 'semaforo-color-rojo' :

                          form.right.vaWithCorrection == '20/200' ? 'semaforo-color-naranja' :
                          form.right.vaWithCorrection == '20/150' ? 'semaforo-color-naranja' :
                          form.right.vaWithCorrection == '20/100' ? 'semaforo-color-naranja' :
                          form.right.vaWithCorrection == '20/80' ? 'semaforo-color-naranja' :

                          form.right.vaWithCorrection == '20/70' ? 'semaforo-color-amarillo' :
                          form.right.vaWithCorrection == '20/60' ? 'semaforo-color-amarillo' :
                          form.right.vaWithCorrection == '20/50' ? 'semaforo-color-amarillo' :

                          form.right.vaWithCorrection == '20/40' ? 'semaforo-color-verde' :
                          form.right.vaWithCorrection == '20/30' ? 'semaforo-color-verde' :
                          form.right.vaWithCorrection == '20/25' ? 'semaforo-color-verde' :
                          form.right.vaWithCorrection == '20/20' ? 'semaforo-color-verde' :
                          form.right.vaWithCorrection == '20/15' ? 'semaforo-color-verde' :
                          form.right.vaWithCorrection == '20/10' ? 'semaforo-color-verde' : ''
                        ]"
                    />
                  </div>
                </div>
                <div class="row-no-center">
                  <p style="width:20px;color:#000000;font-weight:bold;font-size:13px;margin:0px;">
                    OI
                  </p>
                  <div style="padding-left:20px;margin-top:20px">
                    <div class="row-no-center">
                      <el-form-item
                        prop="left.vaNoCorrection"
                        class="small"
                      >
                      <el-input v-model="form.left.vaNoCorrection" />
                      </el-form-item>
                    </div>
                  </div>
                  <div style="padding-left:20px;margin-top:20px">
                    <div class="row-no-center">
                      <el-form-item
                        prop="left.refractionPh"
                        class="small"
                      >
                        <el-input v-model="form.left.refractionPh"/>
                      </el-form-item>
                    </div>
                  </div>
                  <div style="padding-left:20px;">
                    <div class="row-no-center">

                      <el-form-item prop="left.refractionSphere" class="small" label="Esfera">
                      <el-input v-model="form.left.refractionSphere" />
                      </el-form-item>
                      <el-form-item prop="left.refractionCilinder" class="small" label="Cilindro">
                      <el-input v-model="form.left.refractionCilinder" />
                      </el-form-item>
                      <el-form-item prop="left.refractionAxis" class="small" label="Eje">
                      <el-input v-model="form.left.refractionAxis" />
                      </el-form-item>
                      <el-form-item prop="left.refractionAddition" class="small" label="Adición">

                      <el-input v-model="form.left.refractionAddition"/>
                      </el-form-item>
                    </div>
                  </div>
                  <div style="margin-left:20px">
                    <el-form-item
                      prop="left.vaWithCorrection"
                      class="small"
                      label="AV Con corrección"
                    >
                      <el-select
                        filterable
                        v-model="form.left.vaWithCorrection"
                        placeholder="Parámetros de AV"
                        style="width: 170px"
                      >
                        <el-option
                          v-for="item in avParams"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                  <div style="margin-left: 60px">
                    <el-form-item
                      prop="left.intraocularPressure"
                      class="small"
                      label="Presión"
                    >
                      <el-input v-model="form.left.intraocularPressure"/>
                    </el-form-item>
                  </div>
                  <div style="margin-left:20px;margin-top:-5px">
                    <div
                      style="width:200px; height: 45px;"
                      :class="
                        [
                          form.left.vaWithCorrection == 'N/A' ? 'semaforo-color-transparent':
                          form.left.vaWithCorrection == 'No Percibe Luz'?'semaforo-color-negro':
                          form.left.vaWithCorrection == 'Percepción de Luz'?'semaforo-color-negro':
                          form.left.vaWithCorrection == 'Prótesis'?'semaforo-color-negro':

                          form.left.vaWithCorrection == 'Movimiento Manos'? 'semaforo-color-rojo' :
                          form.left.vaWithCorrection == 'Cuenta Dedos' ? 'semaforo-color-rojo' :
                          form.left.vaWithCorrection == '20/500' ? 'semaforo-color-rojo' :
                          form.left.vaWithCorrection == '20/400' ? 'semaforo-color-rojo' :
                          form.left.vaWithCorrection == '20/320' ? 'semaforo-color-rojo' :
                          form.left.vaWithCorrection == '20/250' ? 'semaforo-color-rojo' :

                          form.left.vaWithCorrection == '20/200' ? 'semaforo-color-naranja' :
                          form.left.vaWithCorrection == '20/150' ? 'semaforo-color-naranja' :
                          form.left.vaWithCorrection == '20/100' ? 'semaforo-color-naranja' :
                          form.left.vaWithCorrection == '20/80' ? 'semaforo-color-naranja' :

                          form.left.vaWithCorrection == '20/70' ? 'semaforo-color-amarillo' :
                          form.left.vaWithCorrection == '20/60' ? 'semaforo-color-amarillo' :
                          form.left.vaWithCorrection == '20/50' ? 'semaforo-color-amarillo' :

                          form.left.vaWithCorrection == '20/40' ? 'semaforo-color-verde' :
                          form.left.vaWithCorrection == '20/30' ? 'semaforo-color-verde' :
                          form.left.vaWithCorrection == '20/25' ? 'semaforo-color-verde' :
                          form.left.vaWithCorrection == '20/20' ? 'semaforo-color-verde' :
                          form.left.vaWithCorrection == '20/15' ? 'semaforo-color-verde' :
                          form.left.vaWithCorrection == '20/10' ? 'semaforo-color-verde' : ''
                        ]"
                    />
                  </div>
                </div>

                <div class="row-no-center" style="justify-content:center;">
                  <div>
                    <p
                      style="
                        color:#006EFF;
                        font-weight:bold;
                        font-size:15px!important;
                        margin:0px;
                        text-align:center
                      "
                    >
                      LENSOMETRÍA
                    </p>
                    <br>
                    <div class="row-no-center">
                      <el-form-item prop="right.lensometrySphere" class="small" label="Esfera">
                        <el-input v-model="form.right.lensometrySphere"/>
                      </el-form-item>
                      <el-form-item prop="right.lensometryCilinder" class="small" label="Cilindro">
                        <el-input v-model="form.right.lensometryCilinder" />
                      </el-form-item>
                      <el-form-item prop="right.lensometryAxis" class="small" label="Eje">
                        <el-input v-model="form.right.lensometryAxis"/>
                      </el-form-item>
                      <el-form-item prop="right.lensometryAddition" class="small" label="Adición">
                        <el-input v-model="form.right.lensometryAddition" />
                      </el-form-item>
                      <el-form-item
                        prop="right.vaWithGlasses"
                        class="small"
                        label="AV"
                      >
                        <el-input v-model="form.right.vaWithGlasses"/>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="row-no-center" style="justify-content:center;">
                  <div>
                    <div class="row-no-center">
                      <el-form-item prop="left.lensometrySphere" class="small" label="Esfera">
                        <el-input v-model="form.left.lensometrySphere" />
                      </el-form-item>
                      <el-form-item prop="left.lensometryCilinder" class="small" label="Cilindro">
                        <el-input v-model="form.left.lensometryCilinder" />
                      </el-form-item>
                      <el-form-item prop="left.lensometryAxis" class="small" label="Eje">
                        <el-input v-model="form.left.lensometryAxis" />
                      </el-form-item>
                      <el-form-item prop="left.lensometryAddition" class="small" label="Adición">
                        <el-input v-model="form.left.lensometryAddition" />
                      </el-form-item>
                      <el-form-item
                        prop="left.vaWithGlasses"
                        class="small"
                        label="AV"
                      >
                        <el-input v-model="form.left.vaWithGlasses"/>
                      </el-form-item>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row-no-center">
                <el-form-item
                  prop="info.otherConditions"
                  class="xlarge"
                  label="Otros Padecimientos"
                >
                  <el-input
                    v-model="form.info.otherConditions"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 3 }"
                  />
                </el-form-item>
              </div>
              <el-form-item style="width: 100%!important;">
                <el-button
                  type="primary"
                  @click="beforeSubmit"
                  style="background-color: #6993FF;color:#fff;float:right;"
                >
                  {{ isFirstEvaluation ? 'REGISTRAR' : 'ACTUALIZAR' }}
                </el-button>
                <el-button
                  type="success"
                  @click="docsVisible = true"
                  class="files"
                >
                  ADJUNTAR ARCHIVOS
                </el-button>
              </el-form-item>
              <DocsDialog
                :docsVisible="docsVisible"
                folder="OPTOMETRÍA"
                v-on:close-dialog="closeDialog"
              />
            </el-form>
          </el-main>
        </el-container>
      </div>
    </el-col>
  </el-row>
</template>

<script>
/* eslint-disable no-console */
import { mapState } from 'vuex';
import { Notification } from 'element-ui';
import Title2 from '../../../components/Title2';
import DocsDialog from '../../Docs/DocsDialog';

export default {
  data() {
    const previousSurgery = (rule, value, cb) => {
      if (this.form.info.hasSurgeries && !this.form.info.previousSurgery) {
        return cb(new Error('El campo es requerido.'));
      }
      return cb();
    };
    return {
      evaluationId: null,
      isFirstStatus: false,
      statusId: null,
      patientId: '',
      appointmentId: '',
      docsVisible: false,
      label: 'Por favor llene los datos requeridos para completar la Evaluación',
      form: {
        info: {},
        left: {},
        right: {},
      },
      message: '',
      createdAt: '',
      loading: false,
      loadingService: null,
      eye: {
        vaNoCorrection: null,
        vaWithCorrection: null,
        vaWithGlasses: null,
        lensometrySphere: null,
        lensometryCilinder: null,
        lensometryAxis: null,
        lensometryAddition: null,
        refractionPh: null,
        refractionSphere: null,
        refractionCilinder: null,
        refractionAxis: null,
        refractionAddition: null,
        intraocularPressure: null,
      },
      initialForm: {},
      isFirstEvaluation: false,
      avParams: [
        {
          value: 'N/A',
          label: 'N/A',
        },
        {
          value: 'Percepción de Luz',
          label: 'Percepción de Luz',
        },
        {
          value: 'No Percibe Luz',
          label: 'No Percibe Luz',
        },
        {
          value: 'Prótesis',
          label: 'Prótesis',
        },
        {
          value: 'Cuenta Dedos',
          label: 'Cuenta Dedos',
        },
        {
          value: 'Movimiento Manos',
          label: 'Movimiento Manos',
        },
        {
          value: '20/500',
          label: '20/500',
        },
        {
          value: '20/400',
          label: '20/400',
        },
        {
          value: '20/320',
          label: '20/320',
        },
        {
          value: '20/250',
          label: '20/250',
        },
        {
          value: '20/200',
          label: '20/200',
        },
        {
          value: '20/150',
          label: '20/150',
        },
        {
          value: '20/100',
          label: '20/100',
        },
        {
          value: '20/80',
          label: '20/80',
        },
        {
          value: '20/70',
          label: '20/70',
        },
        {
          value: '20/60',
          label: '20/60',
        },
        {
          value: '20/50',
          label: '20/50',
        },
        {
          value: '20/40',
          label: '20/40',
        },
        {
          value: '20/30',
          label: '20/30',
        },
        {
          value: '20/25',
          label: '20/25',
        },
        {
          value: '20/20',
          label: '20/20',
        },
        {
          value: '20/15',
          label: '20/15',
        },
        {
          value: '20/10',
          label: '20/10',
        },
      ],
      sphere: [],
      cilinder: [],
      axis: [],
      addition: [],
      intraocularPressureOpt: [],
      hasSurgeriesOpt: [
        {
          value: true,
          label: 'Si',
        },
        {
          value: false,
          label: 'No',
        },
      ],
      rules: {
        previousSurgery: [
          {
            validator: previousSurgery,
          },
        ],
      },
    };
  },
  components: {
    Title2,
    DocsDialog,
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.user.role,
    }),
  },
  methods: {
    closeDialog() {
      this.docsVisible = false;
    },
    attach() {},
    beforeSubmit() {
      this.$refs.refId.validate((isValid) => {
        if (isValid) {
          this.submit();
        }
      });
    },
    changedSections() {
      const initial = JSON.stringify(this.initialForm);
      const final = JSON.stringify(this.form);
      const initialInfo = JSON.stringify(this.initialForm.info);
      const finalInfo = JSON.stringify(this.form.info);
      const initialL = JSON.stringify(this.initialForm.left);
      const initialR = JSON.stringify(this.initialForm.right);
      const finalL = JSON.stringify(this.form.left);
      const finalR = JSON.stringify(this.form.right);
      const changes = [];
      if (initial === final) {
        return ['none'];
      }
      if (initialL !== finalL && initialR !== finalR && initialInfo !== finalInfo) {
        return ['full'];
      }
      if (initialL !== finalL && initialR !== finalR) {
        changes.push('eyes');
      }
      if (initialL !== finalL && !changes.includes('eyes')) {
        changes.push('left');
      }
      if (initialR !== finalR && !changes.includes('eyes')) {
        changes.push('right');
      }
      if (initialInfo !== finalInfo) {
        changes.push('info');
      }
      return changes;
    },
    async changeStatus(patient, appointment, type) {
      try {
        if (type === 'POST') {
          await this.axios.post(`patient/status/${patient}/${appointment}`, {
            statusId: 2,
          });
        } else if (this.statusId !== 2) {
          await this.axios.put(`patient/status/${patient}/${appointment}`, {
            statusId: 2,
          });
        }
      } catch (error) {
        // some code
      }
    },
    async submit() {
      try {
        const requests = [];
        let message = '';
        if (this.isFirstEvaluation) {
          const data = this.format();
          const response = await this.axios.post(`patient/opto/${this.patientId}/${this.appointmentId}`, data);
          message = 'Se generó un Perfil Optométrico';
          if (response) {
            await this.changeStatus(this.patientId, this.appointmentId, 'POST');
            await this.axios.post(`patient/changelog/${this.patientId}`, {
              title: 'EVALUACIÓN OPTOMÉTRICA',
              message,
            });
          }
        } else {
          const changes = this.changedSections();
          if (!changes.includes('none')) {
            message = 'Se actualizó el Perfil Optométrico';
            if (changes.includes('full')) {
              const data = this.format();
              requests.push(this.axios.put(`patient/opto/${this.patientId}/${this.evaluationId}`, data));
              const eyes = this.format();
              requests.push(this.axios.put(`patient/opto/eyes/${this.patientId}/${this.evaluationId}`, eyes));
            }
            if (changes.includes('eyes')) {
              const eyes = this.format();
              requests.push(this.axios.put(`patient/opto/eyes/${this.patientId}/${this.evaluationId}`, eyes));
            }
            if (changes.includes('info')) {
              const data = this.formatInfo();
              requests.push(this.axios.put(`patient/opto/${this.patientId}/${this.evaluationId}`, data));
            }
            if (changes.includes('left')) {
              const eye = this.formatEye('left');
              requests.push(this.axios.put(`patient/opto/eye/LEFT/${this.patientId}/${this.evaluationId}`, eye));
            }
            if (changes.includes('right')) {
              const eye = this.formatEye('right');
              requests.push(this.axios.put(`patient/opto/eye/RIGHT/${this.patientId}/${this.evaluationId}`, eye));
            }
          } else {
            message = 'No hay cambios';
          }
          await this.changeStatus(this.patientId, this.appointmentId, 'PUT');
          const response = await Promise.all(requests);
          if (response.length) {
            await this.axios.post(`patient/changelog/${this.patientId}`, {
              title: 'EVALUACIÓN OPTOMÉTRICA',
              message,
            });
          }
        }
        Notification({
          type: 'success',
          title: '¡Éxito!',
          message,
        });
        this.$router.replace('/');
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    formatInfo() {
      const formData = this.form.info;
      const data = {
        weight: formData.weight,
        size: formData.size,
        heartRate: formData.heartRate,
        respiratoryRate: formData.respiratoryRate,
        bloodPressure: formData.bloodPressure,
        temperature: formData.temperature,
        previousSurgery:
          formData.hasSurgeries && formData.previousSurgery ? formData.previousSurgery : null,
        medicines: formData.medicines,
        currentCondition: formData.currentCondition,
        comments: formData.comments,
        otherConditions: formData.otherConditions,
        rxFinal: formData.rxFinal,
      };
      return data;
    },
    format() {
      const { info, left, right } = this.form;
      return {
        weight: info.weight ? info.weight : null,
        size: info.size ? info.size : null,
        heartRate: info.heartRate ? info.heartRate : null,
        respiratoryRate: info.respiratoryRate ? info.respiratoryRate : null,
        bloodPressure: info.bloodPressure ? info.bloodPressure : null,
        temperature: info.temperature ? info.temperature : null,
        medicines: info.medicines ? info.medicines : null,
        currentCondition: info.currentCondition ? info.currentCondition : null,
        comments: info.comments ? info.comments : null,
        otherConditions: info.otherConditions ? info.otherConditions : null,
        rxFinal: info.rxFinal ? info.rxFinal : null,
        previousSurgery: info.hasSurgeries && info.previousSurgery ? info.previousSurgery : null,
        reVaNoCorrection: right.vaNoCorrection ? right.vaNoCorrection : null,
        leVaNoCorrection: left.vaNoCorrection ? left.vaNoCorrection : null,
        reVaWithCorrection: right.vaWithCorrection ? right.vaWithCorrection : null,
        leVaWithCorrection: left.vaWithCorrection ? left.vaWithCorrection : null,
        reVaWithGlasses: right.vaWithGlasses ? right.vaWithGlasses : null,
        leVaWithGlasses: left.vaWithGlasses ? left.vaWithGlasses : null,
        reLensometrySphere: right.lensometrySphere ? right.lensometrySphere : null,
        leLensometrySphere: left.lensometrySphere ? left.lensometrySphere : null,
        reLensometryCilinder: right.lensometryCilinder ? right.lensometryCilinder : null,
        leLensometryCilinder: left.lensometryCilinder ? left.lensometryCilinder : null,
        reLensometryAxis: right.lensometryAxis ? right.lensometryAxis.toString() : null,
        leLensometryAxis: left.lensometryAxis ? left.lensometryAxis.toString() : null,
        reLensometryAddition: right.lensometryAddition ? right.lensometryAddition : null,
        leLensometryAddition: left.lensometryAddition ? left.lensometryAddition : null,
        reRefractionPh: right.refractionPh ? right.refractionPh : null,
        leRefractionPh: left.refractionPh ? left.refractionPh : null,
        reRefractionSphere: right.refractionSphere ? right.refractionSphere : null,
        leRefractionSphere: left.refractionSphere ? left.refractionSphere : null,
        reRefractionCilinder: right.refractionCilinder ? right.refractionCilinder : null,
        leRefractionCilinder: left.refractionCilinder ? left.refractionCilinder : null,
        reRefractionAxis: right.refractionAxis ? right.refractionAxis.toString() : null,
        leRefractionAxis: left.refractionAxis ? left.refractionAxis.toString() : null,
        reRefractionAddition: right.refractionAddition ? right.refractionAddition : null,
        leRefractionAddition: left.refractionAddition ? left.refractionAddition : null,
        reIntraocularPressure: right.intraocularPressure
          ? right.intraocularPressure.toString()
          : null,
        leIntraocularPressure: left.intraocularPressure
          ? left.intraocularPressure.toString()
          : null,
      };
    },
    formatEye(side) {
      if (side === 'left') {
        return {
          patient: this.form.info.patient,
          ...this.form.left,
        };
      }
      if (side === 'right') {
        return {
          patient: this.form.info.patient,
          ...this.form.right,
        };
      }
      return '';
    },
    formatEntry(data) {
      const copy = { ...data };
      delete copy.eyes;
      const left = data.eyes.filter((a) => a.eyeSide.toLowerCase() === 'left')[0];
      const right = data.eyes.filter((a) => a.eyeSide.toLowerCase() === 'right')[0];
      this.form = {
        info: { ...copy },
        left: left || JSON.parse(JSON.stringify(this.eye)),
        right: right || JSON.parse(JSON.stringify(this.eye)),
      };
      if (copy.previousSurgery) {
        this.form.info.hasSurgeries = true;
        this.form.info.previousSurgery = copy.previousSurgery;
      }
      const clone = JSON.parse(JSON.stringify(this.form));
      this.initialForm = clone;
    },
    async getData(appointment) {
      try {
        const response = await this.axios.get(`patient/opto/details/${this.patientId}/${appointment}`);
        const idevaluation = response.data.id;
        this.evaluationId = idevaluation || null;
        if (response.status === 200 && response.data.eyes.length !== 0) {
          this.form.info.patient = this.patientId;
          this.llenarFormInfo(response.data, response.data.eyes[0], response.data.eyes[1]);
          this.loading = false;
        } else {
          if (idevaluation !== undefined) {
            const response2 = await this.axios.get(`patient/opto/anteriorEvaluation/${idevaluation}`);
            const responseEye = await this.axios.get(`patient/opto/anteriorEvaluationEyes/${idevaluation}`);
            this.llenarFormInfo(response2.data[0], responseEye.data[0], responseEye.data[1]);
          }
          this.form.info.patient = this.patientId;
          this.loading = false;
        }
      } catch (error) {
        this.$loading().close();
        this.$message({
          showClose: true,
          message: 'Error al cargar la información',
          type: 'error',
        });
      }
    },
    llenarFormInfo(data, derecha, izquierda) {
      const copy = { ...derecha };
      delete copy.eyes;
      const right = derecha;

      const copy2 = { ...izquierda };
      delete copy2.eyes;
      const left = izquierda;

      if (
        this.isFirstEvaluation
          && (this.appointmentId !== undefined || this.appointmentId !== null)
      ) {
        right.refractionAddition = null;
        right.refractionAxis = null;
        right.refractionCilinder = null;
        right.refractionPh = null;
        right.refractionSphere = null;
        right.intraocularPressure = null;
        right.vaWithCorrection = null;
        left.refractionAddition = null;
        left.refractionAxis = null;
        left.refractionCilinder = null;
        left.refractionPh = null;
        left.refractionSphere = null;
        left.intraocularPressure = null;
        left.vaWithCorrection = null;
      }
      this.form.info = data;
      this.form.left = izquierda;
      this.form.right = derecha;
    },
    async getAppointment() {
      try {
        // OBTIENE LA CITA ANTERIOR PARA OBTENER LOS DATOS
        const response2 = await this.axios.get(`patient/opto/anteriorEvaluationV2/${this.patientId}/${this.appointmentId}`);
        if (response2.data.length !== 0) {
          const { data } = response2;
          this.isFirstEvaluation = parseInt(this.evaluationId, 10) !== data.id;
          await this.getData(data.appointmentSub);
          await this.getStatus(this.patientId, data.appointmentSub);
        } else {
          this.isFirstEvaluation = true;
          this.$notify({
            type: 'info',
            title: 'Nueva evaluación',
            message: 'El expediente no cuenta con evaluación optométrica, se generará una nueva evaluación.',
          });
        }
      } catch (error) {
        this.$notify({
          type: 'info',
          title: 'Nueva evaluación',
          message: 'El expediente no cuenta con evaluación optométrica, se generará una nueva evaluación.',
        });
      }
    },
    async getStatus(patientId, appointmentId) {
      const request = await this.axios(`patient/status/${patientId}/${appointmentId}`);
      if (!request.data) {
        this.isFirstStatus = true;
      } else {
        this.statusId = request.data.id;
      }
    },
  },
  async created() {
    this.$loading();
    this.patientId = this.$route.params.id;
    this.appointmentId = this.$route.params.appointmentId;
    this.evaluationId = this.$route.params.evaluationId;
    await this.getAppointment();
    this.$loading().close();
  },
  watch: {
    loading(val) {
      if (val && !this.loadingService) {
        this.loadingService = this.$loading({
          fullscreen: true,
          lock: true,
          text: 'Cargando',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      } else if (this.loadingService) {
        this.loadingService.close();
        this.loadingService = null;
      }
    },
  },
};
</script>
<style lang="scss">
@import './styles/style.scss';
</style>
<style lang="scss" scoped>
@import './styles/style-scoped.scss';
</style>
